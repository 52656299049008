import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Response } from '../../constants/types'
import { GetHoteliersByHotel } from '../../helpers/api/superAdmin'
import { Hotelier } from '../../types/Item'
import Loader from '../Loader'
import NoDataFound from '../NoDataFound'
import Table from '../Table'

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Deparment',
    accessor: 'department',
  },
  {
    Header: 'Mobile Number',
    accessor: 'contactNo',
  },
  // {
  //   Header: 'Action',
  //   accessor: 'Edit',
  //   Cell: ({row}: any) => {
  //     return (
  //       <Button onClick={() => handleEditHotelierClick(row.values)}>
  //         edit
  //       </Button>
  //     )
  //   },
  // },
  // {
  //   Header: 'Action',
  //   accessor: 'Delete',
  //   Cell: ({row}: any) => {
  //     const id: number = row.values.id
  //     return (
  //       <Button
  //         variant="danger"
  //         onClick={() => {
  //           setShowDeleteConf(true)
  //           setDeleteItemId(id)
  //         }}
  //       >
  //         delete
  //       </Button>
  //     )
  //   },
  // },
  // {
  //   Header: '',
  //   accessor: 'floor',
  //   Cell: <></>,
  // },
]

const Hoteliers = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [hotelierList, setHotelierList] = useState<Hotelier[]>([])

  const getHotelierList = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await GetHoteliersByHotel(hotelId)) as Response
    if (res?.data?.data) {
      setHotelierList(res?.data?.data)
    }
    setPageLoad(false)
  }, [hotelId])

  useEffect(() => {
    getHotelierList()
  }, [getHotelierList])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="mt-2">
        <Card.Body>
          {hotelierList?.length > 0 ? (
            <Table
              columns={columns}
              data={hotelierList}
              isSearchable={true}
              theadClass="table-dark"
            />
          ) : (
            <NoDataFound />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default Hoteliers
