import {APICore} from './apiCore'
import config from '../../config'
import {Response} from '../../constants/types'
import {Admin} from '../../types/Admin'

const api = new APICore()

let baseUrl = config.API_URL

async function createAdmin(data: Admin) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}`
      let result: Promise<Response> = api.createWithFile(url, data)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function login(params: {email: string; password: string}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/login`
      let result: Promise<Response> = api.create(url, params)
      console.log(result)

      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getProfile() {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/profile`
      let result: Promise<Response> = api.get(url, {})
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateProfile(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/profile`
      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getAllAdmins(params?: {page?: number; limit?: number}) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetAdminById(
  id: number,
  params?: {page?: number; limit?: number},
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function blockUnblockAdmin(id: number, data?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/block/admins/${id}`
      let result: Promise<Response> = api.updatePatch(url, data)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function DeleteAdminById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADMINS}/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  createAdmin,
  login,
  getProfile,
  updateProfile,
  getAllAdmins,
  GetAdminById,
  blockUnblockAdmin,
  DeleteAdminById,
}
