export const currencyList = [
  {
    value: '',
    label: 'Select Currency',
  },
  {
    value: 'INR',
    label: 'INR',
  },
  // {
  //   value: 'usd',
  //   label: 'USD',
  // }
]

export const languageList = [
  {
    value: '',
    label: 'Select Language',
  },
  {
    value: 'english',
    label: 'English',
  },
]

export const periodList = [
  {
    value: '',
    label: 'Select Period',
  },
  // {
  //   value: 'daily',
  //   label: 'Daily',
  // },
  // {
  //   value: 'weekly',
  //   label: 'Weekly',
  // },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'yearly',
    label: 'Yearly',
  },
]

export const countryList = [
  {
    value: '',
    label: 'Select Location',
  },
  {
    value: 'india',
    label: 'India',
  },
]

export const stateList = [
  {
    value: '',
    label: 'Select State',
  },
  {
    value: 'AN',
    label: 'Andaman and Nicobar Islands',
  },
  {
    value: 'AP',
    label: 'Andhra Pradesh',
  },
  {
    value: 'AR',
    label: 'Arunachal Pradesh',
  },
  {
    value: 'AS',
    label: 'Assam',
  },
  {
    value: 'BR',
    label: 'Bihar',
  },
  {
    value: 'CG',
    label: 'Chandigarh',
  },
  {
    value: 'CH',
    label: 'Chhattisgarh',
  },
  {
    value: 'DN',
    label: 'Dadra and Nagar Haveli',
  },
  {
    value: 'DD',
    label: 'Daman and Diu',
  },
  {
    value: 'DL',
    label: 'Delhi',
  },
  {
    value: 'GA',
    label: 'Goa',
  },
  {
    value: 'GJ',
    label: 'Gujarat',
  },
  {
    value: 'HR',
    label: 'Haryana',
  },
  {
    value: 'HP',
    label: 'Himachal Pradesh',
  },
  {
    value: 'JK',
    label: 'Jammu and Kashmir',
  },
  {
    value: 'JH',
    label: 'Jharkhand',
  },
  {
    value: 'KA',
    label: 'Karnataka',
  },
  {
    value: 'KL',
    label: 'Kerala',
  },
  {
    value: 'LA',
    label: 'Ladakh',
  },
  {
    value: 'LD',
    label: 'Lakshadweep',
  },
  {
    value: 'MP',
    label: 'Madhya Pradesh',
  },
  {
    value: 'MH',
    label: 'Maharashtra',
  },
  {
    value: 'MN',
    label: 'Manipur',
  },
  {
    value: 'ML',
    label: 'Meghalaya',
  },
  {
    value: 'MZ',
    label: 'Mizoram',
  },
  {
    value: 'NL',
    label: 'Nagaland',
  },
  {
    value: 'OR',
    label: 'Odisha',
  },
  {
    value: 'PY',
    label: 'Puducherry',
  },
  {
    value: 'PB',
    label: 'Punjab',
  },
  {
    value: 'RJ',
    label: 'Rajasthan',
  },
  {
    value: 'SK',
    label: 'Sikkim',
  },
  {
    value: 'TN',
    label: 'Tamil Nadu',
  },
  {
    value: 'TS',
    label: 'Telangana',
  },
  {
    value: 'TR',
    label: 'Tripura',
  },
  {
    value: 'UP',
    label: 'Uttar Pradesh',
  },
  {
    value: 'UK',
    label: 'Uttarakhand',
  },
  {
    value: 'WB',
    label: 'West Bengal',
  },
]
