import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Response } from '../../constants/types'
import { GetContactsByHotel } from '../../helpers/api/superAdmin'
import { Contact } from '../../types/Item'
import Loader from '../Loader'
import NoDataFound from '../NoDataFound'
import Table from '../Table'

const columns = [
  {
    Header: 'Contact ID',
    accessor: 'id',
  },
  {
    Header: 'Department',
    accessor: 'department',
  },
  {
    Header: 'Contact number',
    accessor: 'mobile',
  },
  {
    Header: 'User name',
    accessor: 'name',
  },
]

const HelpDesk = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [contactList, setContactList] = useState<Contact[]>([])

  const getContactList = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await GetContactsByHotel(hotelId)) as Response
    if (res?.data?.data) {
      setContactList(res?.data?.data)
    }
    setPageLoad(false)
  }, [hotelId])

  useEffect(() => {
    getContactList()
  }, [getContactList])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="mt-2">
        <Card.Body>
          {contactList?.length > 0 ? (
            <Table
              columns={columns}
              data={contactList}
              isSearchable={true}
              isExportable={true}
              theadClass="table-dark"
            />
          ) : (
            <NoDataFound />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default HelpDesk
