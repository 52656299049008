import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Offcanvas } from 'react-bootstrap'
import edit from '../../../../src/assets/svgs/edit.svg'
import deleteIcon from '../../../../src/assets/svgs/delete.svg'
import { useHistory, useParams } from 'react-router-dom'
import DeleteConfirmation from '../../../components/DeleteConfirmation'
import Table from '../../../components/Table'
import Loader from '../../../components/Loader'
import {
  addParticularHMS,
  deleteParticularHMS,
  getParticularHMS,
  updateParticularHMS,
} from '../../../helpers/api/hmes'
import { Response } from '../../../constants/types'
import { toast } from 'react-toastify'
import AddData from './AddData'
import EditData from './EditData'

const PartiCularHMS = () => {
  const [methodList, setMethodList] = useState<any[]>([])
  const [editData, setEditData] = useState<any>(null)
  const [deleteHSMId, setDeleteHSMId] = useState<number>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [showDeleteConf, setShowDeleteConf] = useState(false)

  const history = useHistory()

  const { id }: any = useParams()

  // Get List
  const getHMSMethodList = async () => {
    setPageLoad(true)
    let res: Response = (await getParticularHMS({ hmsId: id })) as Response
    // setHotelList(res?.data?.data)
    // console.log(res?.data?.data)
    setMethodList(
      res?.data?.data?.map((ele: any) => {
        return {
          ...ele,
        }
      }),
    )
    setPageLoad(false)
  }

  // Delete
  const handleDeleteHsm = async (id: any) => {
    let res: Response = (await deleteParticularHMS(id)) as Response
    if (res.status === 200) {
      toast.success('Delete item successfully')
      setMethodList((prev) => prev.filter((item) => item.id !== id))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'no',
        Cell: ({ row }: any) => {
          //   console.log(el)
          return <div>{+row?.index + 1}</div>
        },
      },
      {
        Header: 'Method',
        accessor: 'method',
      },
      {
        Header: 'Endpoint',
        accessor: 'endpoint',
      },
      {
        Header: 'Action',
        accessor: 'edit',
        Cell: ({ row }: any) => {
          return (
            <>
              <Button
                variant=""
                onClick={() => {
                  setEditData(row?.original)
                }}
              >
                <img src={edit} alt="edit icon" />
              </Button>
              <Button
                variant=""
                onClick={() => {
                  setShowDeleteConf(true)
                  setDeleteHSMId(row?.original?.id)
                }}
              >
                <img src={deleteIcon} alt="delete icon" />
              </Button>
            </>
          )
        },
      },
    ],
    [history],
  )
  useEffect(() => {
    getHMSMethodList()
  }, [])
  return (
    <>
      {pageLoad && <Loader />}

      {/* Add Modal */}
      <Offcanvas
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false)
        }}
        placement={'end'}
        // style={{ width: '50%' }}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">Add Method</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddData
            onAddItemSuccess={() => {
              setIsModalOpen(false)
              getHMSMethodList()
            }}
            addParticularHMS={addParticularHMS}
          />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Edit Modal */}
      <Offcanvas
        show={editData !== null}
        onHide={() => {
          setEditData(null)
        }}
        placement={'end'}
        // style={{ width: '50%' }}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">Update Method</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EditData
            onEditItemSuccess={() => {
              setEditData(null)
              getHMSMethodList()
            }}
            editParticularHMS={updateParticularHMS}
            editData={editData}
          />
        </Offcanvas.Body>
      </Offcanvas>
      <div className="mt-2">
        <div className="d-flex gap-3 align-items-center">
          <span className="fw-bold fs-3">HMS</span>
          <div className="d-flex gap-1">
            <span>Home</span>
            <span>|</span>
            <span>HMS</span>
          </div>
        </div>

        {/* {plan && (
          <UpdatePlan
            planList={planList}
            editItem={plan}
            updateItem={updateHotelPlan}
            onEditItemSuccess={null}
          />
        )} */}

        <div className="mt-2">
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <span>HMS</span>

                  <Button onClick={() => setIsModalOpen(true)} variant="dark">
                    Add
                  </Button>
                </div>
                {/* <div className="d-flex mt-2 justify-content-end">
                  <CSVLink data={HMSList}>
                    <Button variant="dark">Export CSV</Button>
                  </CSVLink>
                </div> */}
              </Card.Title>
              <Table
                columns={columns}
                data={methodList}
                // getRowProps={onRowClick}
                isSearchable={true}
                theadClass="table-dark"
              />
            </Card.Body>
          </Card>
        </div>
        <DeleteConfirmation
          show={showDeleteConf}
          setShow={setShowDeleteConf}
          bodyText={'Are you sure to delete the HSM ?'}
          onClose={(success) => {
            if (success) {
              if (deleteHSMId) {
                handleDeleteHsm(deleteHSMId)
              }
            }
            setDeleteHSMId(undefined)
          }}
        />
      </div>
    </>
  )
}

export default PartiCularHMS
