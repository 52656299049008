import { useCallback, useEffect, useState } from 'react'
// import DatePicker from 'react-datepicker'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'

// types
import { Response } from '../../constants/types'

// api
import { GetMainAnalytics } from '../../helpers/api/superAdmin'
import Loader from '../../components/Loader'

const Analytics = () => {
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [anl, setAnl] = useState<any>({
    hotels: {
      count: 0,
    },
    hotelsRevenue: {
      amount: '0',
    },
    hotelComRevenue: {
      amount: '0',
    },
  })

  // const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())

  const getMainAnl = useCallback(async (params?: any) => {
    setPageLoad(true)
    let res: Response = (await GetMainAnalytics(params)) as Response
    setAnl(res?.data?.data)
    setPageLoad(false)
  }, [])

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     // getMainAnl({ startDate, endDate })
  //   }
  // }, [endDate, getMainAnl, startDate])

  useEffect(() => {
    getMainAnl()
  }, [getMainAnl])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="mt-3">
        <div className="d-flex gap-3 align-items-center">
          <span className="fw-bold fs-3">Dashboard</span>
          <div className="d-flex gap-1">
            <span>Home</span>
            <span>|</span>
            <span>Dashboards</span>
          </div>
        </div>
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>Date Range</Card.Title>
            <div className="d-flex gap-4">
              <div className="w-100">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="startDate">Start Date</Form.Label>
                  <Form.Control
                    id="startDate"
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                  />
                </Form.Group>
              </div>
              <div className="w-100">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="endDate">End Date</Form.Label>
                  <Form.Control
                    id="endDate"
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                onClick={() => {
                  if (startDate && endDate) getMainAnl({ startDate, endDate })
                }}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  setStartDate('')
                  setEndDate('')
                  getMainAnl()
                }}
              >
                Clear
              </Button>
            </div>
          </Card.Body>
        </Card>
        <Row>
          <Col md={6}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Total Hotels</Card.Title>
                <p className="fw-bold fs-4">{anl?.hotels?.count || 0}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Subscriptions</Card.Title>
                <p className="">Monthly: {anl?.subscriptions?.find((sub: any) => sub?.period === 'monthly')?.newSubscriptions || 0}</p>
                <p className="">Yearly: {anl?.subscriptions?.find((sub: any) => sub?.period === 'yearly')?.newSubscriptions || 0}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Analytics
