import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

// import logo from '../../assets/images/logo.png'
import Loader from '../../components/Loader'
import Table from '../../components/Table'

import { HotelType } from '../../types/Hotel'

type GuestInvoiceItem = {
  id?: number
  item?: string
  quantity?: number
  price: number | string
  SGSTValue: number
  CGSTValue: number
  subTotal: number
}

type Invoice = {
  id: number
  createdAt: string
  guestInvoiceItem: GuestInvoiceItem[]
  itemTotal: number
  CGSTTotal: number
  SGSTTotal: number
  roomNo: string
}

const columns = [
  { Header: 'Order Id', accessor: 'id' },
  { Header: 'Items', accessor: 'item' },
  { Header: 'Quantity', accessor: 'quantity' },
  { Header: 'Price', accessor: 'price' },
  // {
  //   Header: 'SGST',
  //   accessor: 'SGSTValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { SGST = 0, SGSTValue = 0 } = row.original
  //     return SGST !== 0 ? `${SGSTValue}(${SGST}%)` : SGSTValue
  //   },
  // },
  // {
  //   Header: 'CGST',
  //   accessor: 'CGSTValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { CGST = 0, CGSTValue = 0 } = row.original
  //     return CGST !== 0 ? `${CGSTValue}(${CGST}%)` : CGSTValue
  //   },
  // },
  // {
  //   Header: 'IGST',
  //   accessor: 'IGSTValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { IGST = 0, IGSTValue = 0 } = row.original
  //     return IGST !== 0 ? `${IGSTValue}(${IGST}%)` : IGSTValue
  //   },
  // },
  // {
  //   Header: 'GST Without ITC',
  //   accessor: 'GSTWithoutITCValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { GSTWithoutITC = 0, GSTWithoutITCValue = 0 } = row.original
  //     return GSTWithoutITC !== 0 ? `${GSTWithoutITCValue}(${GSTWithoutITC}%)` : GSTWithoutITCValue
  //   },
  // },
  // {
  //   Header: 'Vat',
  //   accessor: 'vatValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { Vat = 0, vatValue = 0 } = row.original
  //     return Vat !== 0 ? `${vatValue}(${Vat}%)` : vatValue
  //   },
  // },
  // {
  //   Header: 'Compensation Cess',
  //   accessor: 'compensationCessValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { compensationCess = 0, compensationCessValue = 0 } = row.original
  //     return compensationCess !== 0 ? `${compensationCessValue}(${compensationCess}%)` : compensationCessValue
  //   },
  // },
  // {
  //   Header: 'Service Tax',
  //   accessor: 'serviceTaxValue',
  //   Cell: ({ row }: { row: any }) => {
  //     const { serviceTax = 0, serviceTaxValue = 0 } = row.original
  //     return serviceTax !== 0 ? `${serviceTaxValue}(${serviceTax}%)` : serviceTaxValue
  //   },
  // },
  { Header: 'Total', accessor: 'subTotal' },
]

type LocationState = {
  state: {
    invoice?: Invoice
    hotel?: HotelType
  }
}

const getTotals = (data: any[], key: string) => {
  let total = 0;
  data.forEach(item => {
    total += item[key];
  });
  return total;
};

const ViewInvoice = () => {
  const history = useHistory()
  const { state } = useLocation() as LocationState
  const invoice = state?.invoice
  const hotel = state?.hotel
  if (!invoice) {
    history.goBack()
  }
  const iniItemList = invoice?.guestInvoiceItem as GuestInvoiceItem[]
  const [itemList, setItemList] = useState(iniItemList)

  useEffect(() => {
    setItemList(prev => [...prev,
    {
      price: "Grand Total",
      CGSTValue: getTotals(itemList, "CGSTValue"),
      GSTWithoutITCValue: getTotals(itemList, "GSTWithoutITCValue"),
      IGSTValue: getTotals(itemList, "IGSTValue"),
      SGSTValue: getTotals(itemList, "SGSTValue"),
      vatValue: getTotals(itemList, "vatValue"),
      compensationCessValue: getTotals(itemList, "compensationCessValue"),
      serviceTaxValue: getTotals(itemList, "serviceTaxValue"),
      subTotal: getTotals(itemList, "subTotal"),
    }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const printDocument = () => {
    const input = document.getElementById('invoiceCard')
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(canvas)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        // pdf.output('dataurlnewwindow');
        pdf.save('download.pdf')
      })
    }
  }

  return (
    <>
      {invoice ? (
        <div>
          {/* <div className="page-title-box mt-2 pb-10 border-bottom-2">
            <div className="col">
              <h2 className="page-title">Invoice</h2>
            </div>
          </div> */}

          <div className="card bg-white mt-4" id="invoiceCard">
            <div className="card-body p-5">
              <p className="text-center fs-3 fw-bold">Tax Invoice</p>

              <div className="d-flex justify-content-between mt-4">
                <div>
                  <p className="fs-4 fw-bold">{hotel?.name}</p>
                  <p>
                    Address: <span>{hotel?.address}</span>
                  </p>
                  <p>
                    GST No.: <span>{hotel?.GST}</span>
                  </p>
                  <p>
                    Invoice No.: <span>{invoice?.id}</span>
                  </p>
                  <p>
                    Invoice Date:{' '}
                    <span>
                      {moment(invoice?.createdAt).local().format('DD-MMM-YYYY')}
                    </span>
                  </p>
                  <p>
                    Room No.:{' '}
                    <span>
                      {invoice?.roomNo}
                    </span>
                  </p>
                </div>

                {/* <div>
                  <img src={logo} alt="" height={49} />
                </div> */}
              </div>
              <hr />
              <div>
                <div className="fs-4 fw-bold">Order summary</div>
              </div>
              <div>
                <Table columns={columns} data={itemList} />
                {/* <div className="col d-flex justify-content-end gap-5 px-4">
                  <p className="fs-5 fw-bold">Grand Total</p>
                  <p className="fs-4 fw-bold">
                    {invoice?.itemTotal +
                      invoice?.CGSTTotal +
                      invoice?.SGSTTotal}
                  </p>
                </div> */}
              </div>

              <div className="col d-flex justify-content-end mt-5">
                <button
                  className="waves-effect waves-light btn btn-primary"
                  type="button"
                  onClick={printDocument}
                >
                  <i className="bi bi-printer-fill"></i> Print
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ViewInvoice
