import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";

// constants
import { LayoutTypes, SideBarTypes } from "../constants";
import * as layoutConstants from "../constants/layout";

// utils
import { changeBodyAttribute } from "../utils";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./Topbar"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const Footer = React.lazy(() => import("./Footer"));
//const RightSidebar = React.lazy(() => import("./RightSidebar"));

const loading = () => <div className=""></div>;

interface VerticalLayoutProps {
  children?: any;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
  const [showSidebarUserInfo, setShowSidebarUserInfo] = useState(false);
  //const [leftSideBarOpen, setLeftSideBarOpen] = useState(true);

  let layoutType = layoutConstants.LayoutTypes.LAYOUT_VERTICAL;
  let layoutWidth = layoutConstants.LayoutWidth.LAYOUT_WIDTH_FLUID;
  let menuPosition = layoutConstants.MenuPositions.MENU_POSITION_FIXED;
  let leftSideBarType = layoutConstants.SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT;
  let leftSideBarTheme = layoutConstants.SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT;
  //let showSidebarUserInfo = false;
  let topbarTheme = layoutConstants.TopbarTheme.TOPBAR_THEME_LIGHT;
  let isOpenRightSideBar = false;

  // const {
  //   layoutWidth,
  //   menuPosition,
  //   leftSideBarTheme,
  //   leftSideBarType,
  //   showSidebarUserInfo,
  //   showTwoToneIcons,
  //   topbarTheme,
  //   isOpenRightSideBar,
  // } = useSelector((state: RootState) => ({
  //   layoutWidth: state.Layout.layoutWidth,
  //   menuPosition: state.Layout.menuPosition,
  //   leftSideBarTheme: state.Layout.leftSideBarTheme,
  //   leftSideBarType: state.Layout.leftSideBarType,
  //   showSidebarUserInfo: state.Layout.showSidebarUserInfo,
  //   showTwoToneIcons: state.Layout.showTwoToneIcons,
  //   topbarTheme: state.Layout.topbarTheme,
  //   isOpenRightSideBar: state.Layout.isOpenRightSideBar,
  // }));

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

  /*
    layout defaults
    */
  useEffect(() => {
    changeBodyAttribute("data-layout-mode", LayoutTypes.LAYOUT_VERTICAL);
  }, []);

  useEffect(() => {
    changeBodyAttribute("data-layout-width", layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    changeBodyAttribute("data-layout-menu-position", menuPosition);
  }, [menuPosition]);

  useEffect(() => {
    changeBodyAttribute("data-sidebar-color", leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeBodyAttribute(
      "data-sidebar-size",
      isMenuOpened ? "default" : "condensed",
    );
  }, [isMenuOpened]);

  useEffect(() => {
    changeBodyAttribute("data-topbar-color", topbarTheme);
  }, [topbarTheme]);

  useEffect(() => {
    changeBodyAttribute("data-sidebar-icon", "twotones");
  }, []);

  useEffect(() => {
    changeBodyAttribute(
      "data-sidebar-showuser",
      showSidebarUserInfo ? "true" : "false",
    );
  }, [showSidebarUserInfo]);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    // setIsMenuOpened((prevState) => !prevState);
    // setShowSidebarUserInfo((prevState) => !prevState);
    let menu_open = isMenuOpened;
    setIsMenuOpened((prevState) => !prevState);

    if (document.body) {
      if (menu_open) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth > 768 && window.innerWidth <= 1028) {
      leftSideBarType = SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;
      setIsMenuOpened(false);
    } else if (window.innerWidth > 1028) {
      leftSideBarType = SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT;
      setIsMenuOpened(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [updateDimensions]);

  const isCondensed: boolean = isMenuOpened;

  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <Topbar
            openLeftMenuCallBack={openMenu}
            hideLogo={false}
            isCondensed={isMenuOpened}
          />
        </Suspense>
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={isMenuOpened} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>

          {/* <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense> */}
        </div>
      </div>
      {/* {isOpenRightSideBar && (
        <Suspense fallback={loading()}>
          <RightSidebar />
        </Suspense>
      )} */}
    </>
  );
};
export default VerticalLayout;
