import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'
import axios from 'axios'

let baseUrl = config.API_URL
const api = new APICore()

async function GetAllHotels(params?: { page?: number; limit?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetAllSubscriptions(params?: { page?: number; limit?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/subscriptions`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetHotelById(
  id: number | string,
  params?: { page?: number; limit?: number },
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetHouseKeepingItemsByHotel(
  id: number | string,
  params: { categoryId?: number | string },
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/housekeeping/items/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}


async function getSingleSubscriptionById(
  id: number | string,
  params: { subId?: number | string },
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/subscriptions/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function getInvoicesOfSubscription(
  id: number | string,
  params: { subId?: number | string },
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/razorpay/subscriptions/invoices/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetRestaurantItemsByHotel(
  id: number | string,
  params: { categoryId?: number | string },
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/restaurants/items/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetContactsByHotel(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/contacts/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetOrdersByHotel(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/orders/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetOrdersById(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/orders/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetPaymentsByHotel(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/transactions/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetSettlementsByHotel(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/settlements/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetInvoicesByHotel(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/invoices/guest/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetPlansByHotel(id: number | string, params: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/subscriptions/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetAllFeedBacks(params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/feedbacks`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetFeedBacksByHotel(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/guests/feedbacks/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetFeedBacksById(id: number, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/feedbacks/id/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deletePlanById(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/plans/id/${id}`
      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetMainAnalytics(params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/admins/analytics`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetAnalyticsOfHotel(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hotels/analytics/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetHoteliersByHotel(id: number | string, params?: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/hoteliers/hotels/${id}`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function GetAllPlans(params?: { page?: number; limit?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/plans`
      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}


async function addItem(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.ADD_PLAN}`;
      console.log(body);

      let result: Promise<Response> = api.create(url, body);
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
}

const addPlan = (body: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}/plans`;
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('AUTH_TOKEN')}` };
      const data = body
      console.log(body);

      let result: Promise<Response> = axios.post(url, data, { headers });
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
}

export {
  GetAllHotels,
  GetHotelById,
  GetHouseKeepingItemsByHotel,
  GetRestaurantItemsByHotel,
  GetContactsByHotel,
  GetOrdersByHotel,
  GetOrdersById,
  GetPaymentsByHotel,
  GetSettlementsByHotel,
  GetInvoicesByHotel,
  GetAllFeedBacks,
  GetFeedBacksByHotel,
  GetFeedBacksById,
  GetMainAnalytics,
  GetHoteliersByHotel,
  GetAnalyticsOfHotel,
  GetAllPlans,
  addItem,
  GetAllSubscriptions,
  GetPlansByHotel,
  deletePlanById,
  getSingleSubscriptionById,
  getInvoicesOfSubscription,
  addPlan
}
