import React from 'react'

export const ProfileContext = React.createContext({
  userName: '',
  setNewUsername: (newUserName: string, imageUrl: string) => {},
  userImg: '',
  isAdmin: Boolean(localStorage.getItem('isAdmin')),
  setIsAdmin: (isAdmin: boolean) => {},
  getProfileData: () => {},
  user: {
    floors: 0,
    id: 0,
    name: 'Admin',
    image: '',
    email: '',
    contactNo: '',
    address: '',
    GST: '',
    IFSC: '',
    PAN: '',
    bancAccHolderName: '',
    bancAccNo: '',
    bancBranchName: '',
  },
})
