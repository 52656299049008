import React, { useState } from 'react'
import Spinners from '../../components/Spinner'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FormInput } from '../../components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

type propsType = {
  onEditItemSuccess: any
  editHMS: any
  editData: any
}

interface HMSItem {
  hms?: string
  authEndpoint?: string
}

const schemaResolver = yupResolver(
  yup.object().shape({
    authEndpoint: yup.string().required('Please enter Endpoint'),
    hms: yup.string().required('Please enter HMS'),
  }),
)

const UpdateHMS = (props: propsType) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { editHMS, onEditItemSuccess, editData } = props

  const methods = useForm<HMSItem>({
    defaultValues: {
      authEndpoint: editData?.authEndpoint,
      hms: editData?.hms,
    },

    resolver: schemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods
  const onSubmit = async (formData: HMSItem) => {
    console.log(formData)
    try {
      setLoading(true)

      let res: Response = (await editHMS(editData.id, formData)) as Response

      if (res.status === 200) {
        toast.success('Edit HMS successfully.')
        onEditItemSuccess()
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row>
      <Col>
        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
          {/* <FormInput
            label="HMS"
            type="text"
            name="hms"
            register={register}
            key="hms"
            required
            errors={errors}
            control={control}
            containerClass={'mb-3'}
          /> */}
          <Form.Group className="">
            <Form.Label column htmlFor="hms" className="">
              HMS
            </Form.Label>
            <Col lg={10}>
              <FormInput
                type="text"
                name="hms"
                className="form-control chat-input"
                placeholder="Enter HMS"
                register={register}
                key="hms"
                errors={errors}
                control={control}
              />
            </Col>
            <Form.Label column htmlFor="authEndpoint" className="">
              Endpoint
            </Form.Label>
            <Col lg={10}>
              <FormInput
                type="text"
                name="authEndpoint"
                className="form-control chat-input"
                placeholder="Enter Endpoint"
                register={register}
                key="authEndpoint"
                errors={errors}
                control={control}
              />
            </Col>
          </Form.Group>
          <div className="text-md-end mb-0">
            {loading ? (
              <Spinners classname="px-3" />
            ) : (
              <Button variant="primary" className="mt-2" type="submit">
                Update HMS
              </Button>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default UpdateHMS
