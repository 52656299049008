import { useState } from 'react'

type PropsType = any

const ReadMore = ({ children, charLimit = 150 }: PropsType) => {
  const text = children
  const isLargeText = text?.length > charLimit
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  if (!isLargeText) {
    return children
  }
  return (
    <p>
      {isReadMore ? text.slice(0, charLimit) : text}
      <span
        onClick={toggleReadMore}
        className="link-primary"
        style={{
          cursor: 'pointer',
        }}
      >
        {isReadMore ? '...Read more' : ' Show less'}
      </span>
    </p>
  )
}

export default ReadMore
