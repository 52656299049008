import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap'

import {
  HelpDesk,
  HotelAnalytics,
  HotelFeedback,
  Hoteliers,
  // HotelInvoices,
  HotelOrder,
  HotelTransaction,
  HouseKeeping,
  Restaurant,
} from '../../components/Hotel'

// assets
import { AccountSVG, BedSVG, UserSVG, WifiSVG } from '../../../src/assets/svgs'

import { Location } from 'history'
import { HotelType } from '../../types/Hotel'
import HotelSettlements from '../../components/Hotel/HotelSettlements'
import HotelSubscriptions from '../../components/Hotel/HotelSubscriptions'

interface TabContentType {
  id: number
  title: string
  icon?: string
  component?: JSX.Element
}

const Hotel = () => {
  const location: Location<{ hotel: any }> = useLocation()
  const [hotel, setHotel] = useState<HotelType>()
  useEffect(() => {
    if (location.state && location.state.hotel) setHotel(location.state.hotel)
  }, [location])

  if (hotel) {
    const tabContents: TabContentType[] = [
      {
        id: 1,
        title: 'Analytics',
        component: <HotelAnalytics />,
      },
      // {
      //   id: 2,
      //   title: 'Settlements',
      //   component: <HotelSettlements hotel={hotel} />,
      // },
      {
        id: 11,
        title: 'Subscriptions',
        component: <HotelSubscriptions hotel={hotel} />,
      },
      // {
      //   id: 3,
      //   title: 'Invoices',
      //   component: <HotelInvoices hotel={hotel} />,
      // },
      // {
      //   id: 4,
      //   title: 'Transaction',
      //   component: <HotelTransaction />,
      // },
      {
        id: 5,
        title: 'Feedback',
        component: <HotelFeedback />,
      },
      {
        id: 6,
        title: 'Staff',
        component: <Hoteliers />,
      },
      {
        id: 7,
        title: 'Help Desk',
        component: <HelpDesk />,
      },
      {
        id: 8,
        title: 'Order',
        component: <HotelOrder />,
      },
      {
        id: 9,
        title: 'Restaurant',
        component: <Restaurant />,
      },
      {
        id: 10,
        title: 'HouseKeeping',
        component: <HouseKeeping />,
      },
    ]

    return (
      <>
        <Row className="mt-2">
          <Col>
            <Card bg="dark" text="white" className="h-100">
              <Card.Body>
                <img src={UserSVG} alt="user icon" />
                <div className="mt-2">
                  <p className="fw-bold fs-3">{hotel.name}</p>
                  {/* <p>{hotel.email}</p> */}
                  {/* <p>{hotel.contactNo}</p> */}
                  {/* <p>{hotel.GST}</p> */}
                  <p>{hotel.address}</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body className="">
                <img src={AccountSVG} alt="account icon" />
                <div
                  className="mt-2"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    height: '80%',
                  }}
                >
                  <p>
                    {`Contact No. : `}
                    <span className="fw-bolder fs-4">{hotel.contactNo}</span>
                  </p>
                  <p>
                    {`Email Address : `}
                    <span className="fw-bolder fs-4">{hotel.email}</span>
                  </p>
                  <p>
                    {`GST : `}
                    <span className="fw-bolder fs-4">{hotel.GST}</span>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                  <img src={BedSVG} alt="bed icon" />
                  {hotel?.wifiName && <img src={WifiSVG} alt="wifi icon" />}
                </div>
                <div className="d-flex justify-content-between mt-2 h-75">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      height: '80%',
                    }}
                  >
                    <div>
                      Floors :{' '}
                      <span className="fw-bolder fs-4">{hotel.floors}</span>
                    </div>
                    <div>
                      Rooms :{' '}
                      <span className="fw-bolder fs-4">{hotel.rooms}</span>
                    </div>
                  </div>
                  {hotel?.wifiName && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        height: '80%',
                      }}
                    >
                      <div>
                        Wifi Name :{' '}
                        <span className="fw-bolder fs-4">{hotel.wifiName}</span>
                      </div>
                      <div>
                        Password :{' '}
                        <span className="fw-bolder fs-4">
                          {hotel.wifiPassword}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card className="mt-2">
          <Card.Body>
            <Tab.Container defaultActiveKey="Restaurant">
              <Nav as="ul" justify variant="pills" className="p-1">
                {(tabContents || []).map((tab, index) => {
                  return (
                    <Nav.Item as="li" key={index}>
                      <Nav.Link className="cursor-pointer" eventKey={tab.title}>
                        {/* <span className="d-block d-sm-none">
                          <i className={tab.icon}></i>
                        </span> */}
                        <span>{tab.title}</span>
                      </Nav.Link>
                    </Nav.Item>
                  )
                })}
              </Nav>
              <Tab.Content className="text-muted">
                {(tabContents || []).map((tab, index) => {
                  return (
                    <Tab.Pane
                      eventKey={tab.title}
                      id={String(tab.id)}
                      key={index}
                    >
                      {tab.component}
                    </Tab.Pane>
                  )
                })}
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </>
    )

  }
  return null
}

export default Hotel
