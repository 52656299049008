import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, Offcanvas } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Table from '../../components/Table'

// assets
import Eye from '../../../src/assets/svgs/eye.svg'

// types
import { Response } from '../../constants/types'

import { GetAllHotels } from '../../helpers/api/superAdmin'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import UpdatePlan from './UpdatePlan'
import { updateHotelPlan } from '../../helpers/api/updatePlan'

const Hotels = () => {
  const history = useHistory()

  const [hotelList, setHotelList] = useState<any[]>([])
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [plan, setPlan] = useState<any>(null)

  const planDetails = (planNo: any) => {
    if (+planNo === 0) {
      return 'Select  Plan'
    } else if (+planNo === 1) {
      return 'Block'
    } else if (+planNo === 2) {
      return 'Basic plan'
    } else if (+planNo === 3) {
      return 'Standard plan without invoice'
    } else if (+planNo === 4) {
      return 'Standard plan with invoice'
    } else if (+planNo === 5) {
      return 'Premium plan without invoice'
    } else if (+planNo === 6) {
      return 'Premium plan with invoice'
    } else {
      return
    }
  }

  const planList = [
    {
      id: 1,
      title: 'Block',
    },
    {
      id: 2,
      title: 'Basic plan',
    },
    {
      id: 3,
      title: 'Standard plan without invoice',
    },
    {
      id: 4,
      title: 'Standard plan with invoice',
    },
    {
      id: 5,
      title: 'Premium plan without invoice',
    },
    {
      id: 6,
      title: 'Premium plan with invoice',
    },
  ]

  const getHotelList = async () => {
    setPageLoad(true)
    let res: Response = (await GetAllHotels()) as Response
    // setHotelList(res?.data?.data)
    // console.log(res?.data?.data)
    setHotelList(
      res?.data?.data?.map((ele: any) => {
        return {
          ...ele,
          planDetail: planDetails(ele?.plan),
        }
      }),
    )
    setPageLoad(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Hotel Id',
        accessor: 'id',
      },
      {
        Header: 'Hotel Name',
        accessor: 'name',
      },
      {
        Header: 'Hotel Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'contactNo',
      },
      {
        Header: 'Plan',
        accessor: 'planDetail',
        Cell: ({ row }: any) => {
          return (
            <div
              onClick={() => {
                const hotel = row?.original
                // history.push(`/hotel/${hotel.id}`, { hotel })
                // console.log('plan is clicked')
                // console.log(hotel?.planDetail)
                setPlan((prev: any) => {
                  return {
                    ...prev,
                    ...hotel,
                    planId: +hotel?.plan,
                  }
                })
              }}
              style={{ cursor: 'pointer' }}
            >
              {/* <img src={Eye} alt="eye icon" /> */}
              <p style={{ fontWeight: 700 }}>
                {row?.original?.planDetail}
                <i
                  className="bi bi-caret-down-fill "
                  style={{ marginLeft: '2px' }}
                ></i>
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Joined at',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          const joinedAt = row.original?.createdAt
          return <div>{moment(joinedAt).local().format('MMMM DD, yyyy')}</div>
        },
      },
      {
        Header: 'Action',
        accessor: 'edit',
        Cell: ({ row }: any) => {
          return (
            <Button
              variant="light rounded-circle"
              onClick={() => {
                const hotel = row?.original
                history.push(`/hotel/${hotel.id}`, { hotel })
              }}
            >
              <img src={Eye} alt="eye icon" />
            </Button>
          )
        },
      },
    ],
    [history],
  )

  // const onRowClick = (state: any, rowInfo: any, column: any, instance: any) => {
  //   return {
  //     onClick: (e: Event) => {
  //       const hotel = rowInfo?.row?.original
  //       history.push(`/hotel/${hotel.id}`, {hotel})
  //     },
  //   }
  // }

  useEffect(() => {
    getHotelList()
  }, [])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="mt-2">
        <div className="d-flex gap-3 align-items-center">
          <span className="fw-bold fs-3">Hotels</span>
          <div className="d-flex gap-1">
            <span>Home</span>
            <span>|</span>
            <span>Hotels</span>
          </div>
        </div>
        <Offcanvas
          show={plan !== null}
          onHide={() => {
            setPlan(null)
          }}
          placement={'end'}
          // style={{ width: '50%' }}
          backdrop="static"
        >
          <Offcanvas.Header closeButton>
            <h5 id="offcanvasTopLabel">Update Plan</h5>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {plan && (
              <UpdatePlan
                planList={planList}
                editItem={plan}
                updatePlan={updateHotelPlan}
                onEditItemSuccess={({
                  plan,
                  data,
                }: {
                  plan?: any
                  data: any
                }) => {
                  setPlan(null)
                  getHotelList()
                  // setSelectedCategoryId(cat || 0)
                }}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
        {/* {plan && (
          <UpdatePlan
            planList={planList}
            editItem={plan}
            updateItem={updateHotelPlan}
            onEditItemSuccess={null}
          />
        )} */}
        <div className="mt-2">
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <span>Hotels</span>
                  <CSVLink data={hotelList}>
                    <Button variant="dark">Export CSV</Button>
                  </CSVLink>
                </div>
              </Card.Title>
              <Table
                columns={columns}
                data={hotelList}
                // getRowProps={onRowClick}
                isSearchable={true}
                theadClass="table-dark"
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Hotels
