import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import { Response } from '../../constants/types'
import { GetInvoicesByHotel, GetPlansByHotel } from '../../helpers/api/superAdmin'
import { InvoiceType } from '../../types/Item'
import Loader from '../Loader'
import NoDataFound from '../NoDataFound'
import Table from '../Table'
import { HotelType } from '../../types/Hotel'
import { EyeSVG } from '../../assets/svgs'



const HotelSubscriptions = (props: { hotel: HotelType }) => {
  const { hotel } = props
  const history = useHistory()
  const { hotelId } = useParams<{ hotelId: string }>()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [plans, setPlans] = useState<any[]>([])
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')

  const columns = [
    {
      Header: 'Plan Id',
      accessor: 'newPlanId',
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status',
    // },
    // {
    //   Header: 'Charge/Room',
    //   accessor: 'chargePrRoom',
    // },
    {
      Header: 'Hotel Name',
      accessor: 'hotel.name',
    },
    {
      Header: 'Subscribed At',
      accessor: 'createdAt',
      Cell: ({ row }: any) => {
        const joinedAt = row.original?.createdAt
        return <div>{moment(joinedAt).local().format('MMM DD, yyyy')}</div>
      },
    },
    {
      Header: 'Expires At',
      accessor: 'expiryDate',
      Cell: ({ row }: any) => {
        const expiryDate = row.original?.expiryDate
        return <div>{moment(expiryDate).local().format('MMM DD, yyyy')}</div>
      },
    },
    {
      Header: 'Action',
      accessor: 'edit',
      Cell: ({ row }: any) => {
        return (
          <Button
            variant="light rounded-circle"
            onClick={() => {
              const subscription = row?.original
              history.push(`/print-invoice/subscriptions/${subscription.id}`)
            }}
          >
            <img src={EyeSVG} alt="eye icon" />
          </Button>
        )
      },
    },
    // {
    //   Header: 'Expires At',
    //   accessor: 'createdAt',
    //   Cell: ({ row }: any) => {
    //     const expiresAt = row.original?.expiryDate
    //     return <div>{moment(expiresAt).format('MMM DD, yyyy')}</div>
    //   },
    // },
  ]

  const getPlans = useCallback(
    async (params?: any) => {
      setPageLoad(true)
      let res: Response = (await GetPlansByHotel(
        hotelId,
        params,
      )) as Response
      if (res?.data?.data) {
        setPlans(res?.data?.data)
      }
      setPageLoad(false)
    },
    [hotelId],
  )

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     getInvoiceList({ startDate, endDate })
  //   }
  // }, [endDate, getInvoiceList, startDate])

  useEffect(() => {
    getPlans()
  }, [getPlans])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="mt-2">
        <Card.Body>
          <Card className="">
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <span>Date Range</span>
                  {/* <CSVLink data={plans}>
                    <Button variant="dark">Export CSV</Button>
                  </CSVLink> */}
                </div>
              </Card.Title>
              <div className="d-flex gap-4">
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="startDate">Start Date</Form.Label>
                    <Form.Control
                      id="startDate"
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="endDate">End Date</Form.Label>
                    <Form.Control
                      id="endDate"
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  onClick={() => {
                    getPlans({ startDate, endDate })
                  }}
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    setStartDate('')
                    setEndDate('')
                    getPlans()
                  }}
                >
                  Clear
                </Button>
              </div>
            </Card.Body>
          </Card>
          {plans?.length > 0 ? (
            <Table
              columns={columns}
              data={plans}
              // isSearchable={true}
              theadClass="table-dark"
            />
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: '150px' }}
            >
              <NoDataFound />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default HotelSubscriptions
