import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Response } from '../../constants/types'
import { GetFeedBacksByHotel } from '../../helpers/api/superAdmin'

import Expression from './Expression'
import Loader from '../Loader'
import NoDataFound from '../NoDataFound'
import Table from '../Table'
import ReadMore from '../../utils/components/ReadMore'
import moment from 'moment'

const columns = [
  {
    Header: 'Room No.',
    accessor: 'room.roomNo',
  },
  {
    Header: 'Feedback',
    accessor: 'feedback',
    Cell: ({ value }: any) => {
      return <ReadMore>{value}</ReadMore>
    },
  },
  {
    Header: 'Expression',
    accessor: 'rating',
    Cell: Expression,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const createdAt = row.original?.createdAt
      return <div>{moment(createdAt).local().format('MMM DD, yyyy')}</div>
    },
  },
  {
    Header: 'Time',
    accessor: '',
    Cell: ({ row }: any) => {
      const createdAt = row.original?.createdAt
      return <div>{moment(createdAt).local().format('hh:mm A')}</div>
    },
  },
]

const HotelFeedback = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [feedbackList, setFeedbackList] = useState([])

  const getFeedbackList = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await GetFeedBacksByHotel(hotelId)) as Response
    if (res?.data?.data) {
      setFeedbackList(res?.data?.data)
    }
    setPageLoad(false)
  }, [hotelId])

  useEffect(() => {
    getFeedbackList()
  }, [getFeedbackList])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="mt-2">
        <Card.Body>
          {feedbackList?.length > 0 ? (
            <Table
              columns={columns}
              data={feedbackList}
              isSearchable={true}
              isExportable={true}
              theadClass="table-dark"
            />
          ) : (
            <NoDataFound />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default HotelFeedback
