import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap'
import { getInvoicesOfSubscription, getSingleSubscriptionById } from '../../helpers/api/superAdmin'
import Loader from '../../components/Loader'
import moment from 'moment'
import Table from '../../components/Table'

const NewPlan = () => {
  const history = useHistory()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [subscription, setSubscription] = useState<any[any]>([])
  const [invoiceDetails, setInvoiceDetails] = useState<any>({})
  const { subId } = useParams<{ subId: string }>()

  const columnsInvoiceTypes: any = [
    {
      Header: 'Invoice No.',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Date',
      accessor: 'issued_at',
      Cell: ({ row }: any) => {
        const issued_at = row.original?.issued_at
        return <div>{moment(issued_at).local().format('MMM DD, yyyy')}</div>
      },
    },

    {
      Header: 'Action',
      accessor: 'edit',
      Cell: ({ row, value }: any) => {
        console.log('value', row?.original)

        return (
          <div className="d-flex justify-content-center gap-2">
            <Button
              onClick={() => {
                console.log('qwerty', {
                  ...invoiceDetails,
                  invoice: row?.original,
                })

                history.push(`/print-invoice/subscriptions/${subId}`, {
                  ...invoiceDetails,
                  invoice: row?.original,
                })
              }}
            >
              View
            </Button>
          </div>
        )
      },
    },
  ]

  const getSubscription = async () => {
    setPageLoad(true)
    let res: Response | any = (await getSingleSubscriptionById(subId, {})) as Response

    console.log('Get all subs response', res?.data?.data);
    setSubscription(res?.data?.data)
    setPageLoad(false)
  }

  const getInvoices = async () => {
    let res: Response | any = (await getInvoicesOfSubscription(
      subId,
      {},
    )) as Response

    if (res) {
      setInvoiceDetails(res?.data)
    }

  }

  useEffect(() => {
    getSubscription()
    getInvoices()
  }, [])


  console.log('sub', subscription);


  return (
    <>
      {pageLoad && <Loader />}


      <Row className="mt-4" >
        <Col>
          <Card className="">
            <Card.Body>
              <div className="">
                <p className="my-1 mt-0" style={{ fontSize: '15px' }}>
                  <span className="fw-medium  my-1">Subscription ID : </span>{' '}
                  {subscription?.id}
                </p>
                <hr />
                <Row style={{ marginLeft: '1rem ' }}>
                  <Col>
                    <p className="my-1 mt-0" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Plan Details : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Hotel Name : </span>{' '}
                      {subscription?.hotel?.name}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Plan ID : </span>{' '}
                      {subscription?.plan_id}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Plan Name : </span>{' '}
                      {subscription?.planName}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Status : </span>{' '}
                      {subscription?.status}
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Charge/Month/Room : </span>{' '}
                      {subscription?.chargePrRoom}
                    </p>
                    <p className="my-1 mt-3" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Payment Details : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Payment Method : </span>{' '}
                      {subscription?.payment_method}
                    </p>
                    <p className="my-1 mb-0" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Paid Payments : </span>
                      {subscription?.paid_count}
                    </p>
                    {/* <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Total Payments : </span>{' '}
                      {subscription?.total_count}
                    </p> */}
                  </Col>
                  <Col>
                    <p className="my-1 mt-0" style={{ fontSize: '14px' }}>
                      <span className="fw-bold  my-1">Important Dates : </span>
                    </p>
                    <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Join Date : </span>{' '}
                      {moment(subscription?.createdAt).local().format('DD/MM/yyyy')}
                    </p>
                    {/* <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Start Date : </span>{' '}
                      {moment(subscription?.startDate).local().format('DD/MM/yyyy')}
                    </p> */}
                    {/* <p className="my-1" style={{ fontSize: '12px' }}>
                      <span className="fw-medium my-1">Expiry Date : </span>{' '}
                      {new Date(subscription?.expiryDate).toLocaleDateString()}
                    </p> */}
                    {subscription?.lastPaidOn && (
                      <p className="my-1" style={{ fontSize: '12px' }}>
                        <span className="fw-medium my-1"> Last Paid On : </span>{' '}
                        {moment(subscription?.lastPaidOn).local().format('DD/MM/yyyy')}
                      </p>
                    )}
                    {subscription?.nextPayOn && (
                      <p className="my-1" style={{ fontSize: '12px' }}>
                        <span className="fw-medium my-1"> Next Pay On : </span>{' '}
                        {moment(subscription?.nextPayOn).local().format('DD/MM/yyyy')}
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Card>
        <Card.Body>
          <Table
            columns={columnsInvoiceTypes}
            data={invoiceDetails?.invoiceData || []}
            pagination={true}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default NewPlan;
