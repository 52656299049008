import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Offcanvas } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { EyeSVG } from "../../assets/svgs";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Loader from "../../components/Loader";
import AddPlan from "../../components/plans/AddPlan";
import Table from "../../components/Table";
import { ProfileContext } from "../../Context";
import { addPlan, deletePlanById, GetAllPlans, GetAllSubscriptions } from "../../helpers/api/superAdmin";
import classes from "./plans.module.css";


interface plan {
  id: number;
  chargePrRoomPrMonth: number;
  currency: string,
  durationMonth: number,
  GST: number,
  createdAt: string
}



const ViewPlans = () => {

  const history = useHistory()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [plans, setPlans] = useState<plan[]>([])
  const [subscriptions, setSubscriptions] = useState<any[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [formType, setFormType] = useState<string>('Add Item')
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [showDeleteConf, setShowDeleteConf] = useState<boolean>(false)
  // const [selectedPlan, setSelectedPlan] = useState<any>({})
  const [deleteItemId, setDeleteItemId] = useState<number>()





  const getPlans = async (params?: any) => {
    setPageLoad(true)
    let res: Response | any = (await GetAllPlans(params)) as Response

    setPlans(res?.data?.data)
    setPageLoad(false)
  }

  // const subscribePlan = useCallback(
  //   async (id?: any, params?: any) => {
  //     try {
  //       setPageLoad(true)
  //       let res: Response | any = (await subscribePlanById({
  //         plan_id: id,
  //       })) as Response
  //       if (res?.data?.status == 200) {
  //         toast.success(capitalize(res?.data?.message))
  //       }
  //     } catch (error: any) {
  //       toast.error(capitalize(error.errorMessage))
  //     } finally {
  //       setPageLoad(false)
  //       getSubscriptions()
  //       getPlans()
  //     }
  //   },
  //   []
  // )

  const getSubscriptions = async (params?: any) => {
    setPageLoad(true)
    let res: Response | any = (await GetAllSubscriptions(params)) as Response

    setSubscriptions(res?.data?.data)
    setPageLoad(false)
  }

  const toggle = () => {
    setVisible((prev) => !prev)
  }

  const handleAddItemClick = () => {
    toggle()
    setFormType('Add Plan')
  }

  const handleDeletePlan = async (id: any) => {
    let res: Response = (await deletePlanById(id)) as Response
    if (res.status === 200) {
      toast.success('Plan deleted successfully')
      setPlans((prev) => prev.filter((item) => item.id !== id))
    }
  }

  useEffect(() => {
    getPlans()
    getSubscriptions()
  }, [])


  // const handleDeletePlan = async (id: any) => {
  //   let res: Response = (await deletePlanById(id)) as Response
  //   if (res.status === 200) {
  //     toast.success('Plan deleted successfully')
  //     setPlans((prev) => prev.filter((item) => item.id !== id))
  //   }
  // }


  const columns = useMemo(
    () => [
      {
        Header: 'Plan Id',
        accessor: 'newPlanId',
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      // },
      // {
      //   Header: 'Charge/Room',
      //   accessor: 'chargePrRoom',
      // },
      {
        Header: 'Hotel Name',
        accessor: 'hotel.name',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Subscribed At',
        accessor: 'createdAt',
        Cell: ({ row }: any) => {
          const joinedAt = row.original?.createdAt
          return <div>{moment(joinedAt).local().format('MMM DD, yyyy')}</div>
        },
      },
      {
        Header: 'Expires At',
        accessor: 'expiryDate',
        Cell: ({ row }: any) => {
          const expiryDate = row.original?.expiryDate
          return <div>{moment(expiryDate).local().format('MMM DD, yyyy')}</div>
        },
      },
      {
        Header: 'Action',
        accessor: 'edit',
        Cell: ({ row }: any) => {
          return (
            <Button
              variant="light rounded-circle"
              onClick={() => {
                const subscription = row?.original
                history.push(`/print-invoice/subscriptions/${subscription.id}`, { subscription })
              }}
            >
              <img src={EyeSVG} alt="eye icon" />
            </Button>
          )
        },
      },
      // {
      //   Header: 'Expires At',
      //   accessor: 'createdAt',
      //   Cell: ({ row }: any) => {
      //     const expiresAt = row.original?.expiryDate
      //     return <div>{moment(expiresAt).local().format('MMM DD, yyyy')}</div>
      //   },
      // },
    ],
    [history],
  )

  // console.log('subscriptions', subscriptions);
  console.log('plans', plans);

  const activePlans = plans?.filter((plan: any) => plan?.item?.active)

  const { user } = useContext<any>(ProfileContext)


  console.log(1212, user);




  return (
    <>
      {pageLoad && <Loader />}

      <Offcanvas
        show={visible}
        onHide={toggle}
        placement={'end'}
        backdrop="static"
      >
        <Offcanvas.Header closeButton>
          <h5 id="offcanvasTopLabel">{formType}</h5>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {formType && formType === 'Add Plan' && (
            <AddPlan
              addPlan={(addPlan)}
              onAddItemSuccess={() => {
                toggle();
                getPlans()
              }}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <div className="mt-3">
        <div className="d-flex gap-3 align-items-center">
          <span className="fw-bold fs-3">Plans</span>
          <div className="d-flex gap-1">
            <span>Home</span>
            <span>|</span>
            <span>Plans</span>
          </div>
          <div className="col"></div>
          <Button variant="dark" onClick={handleAddItemClick}>Add Plan</Button>
        </div>


        <Card className="mt-2">
          <Card.Body>
            <div
              className="d-flex flex-row gap-3"
              style={{ overflowX: 'scroll' }}
            >
              {plans?.map((plan: any) => (
                <div
                  key={plan?.id}
                  className={classes.planCard}
                // style={{
                //   border:
                //     plan?.id == selectedPlan?.id ? '1.5px solid black' : 'none',
                // }}
                // onClick={() => setSelectedPlan(plan)}
                >
                  <div className="actions w-100 d-flex justify-content-end p-2">
                    <FeatherIcon
                      icon='x'
                      onClick={() => {
                        setShowDeleteConf(true)
                        setDeleteItemId(plan?.id)
                      }}
                    />
                  </div>
                  {/* <p>Get a free trial for 30 days then just!</p> */}
                  <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                    <h1 className="my-0" style={{ whiteSpace: 'nowrap' }} >₹{plan?.priceWithoutTax}</h1>
                    <p className="" >
                      <span style={{ fontSize: "12px", textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                        +{+plan?.GST} % GST
                      </span>
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <p className="mb-0">Per room</p>
                    <p className=" ">
                      Billed
                      {/* <span style={{ textTransform: 'capitalize' }}> */}
                      {" " + plan?.period}
                      {/* </span> */}
                    </p>
                  </div>
                  {/* <Button onClick={() => subscribePlan(plan?.id)} variant="dark">
                  Subscribe
                </Button> */}
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>

        <Card className="mt-3 p-3 ">
          <Card.Title>
            <div className="d-flex justify-content-between">
              <span>History</span>
              {/* <CSVLink data={hotelList}>
                <Button variant="dark">Export CSV</Button>
              </CSVLink> */}
            </div>
          </Card.Title>

          <Card className="">
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <span>Date Range</span>
                  <CSVLink data={subscriptions}>
                    <Button variant="dark">Export CSV</Button>
                  </CSVLink>
                </div>
              </Card.Title>
              <div className="d-flex gap-4">
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="startDate">Start Date</Form.Label>
                    <Form.Control
                      id="startDate"
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="endDate">End Date</Form.Label>
                    <Form.Control
                      id="endDate"
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  onClick={() => {
                    getSubscriptions({ startDate, endDate })
                  }}
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    setStartDate('')
                    setEndDate('')
                    getPlans()
                  }}
                >
                  Clear
                </Button>
              </div>
            </Card.Body>
          </Card>

          <Table
            columns={columns}
            data={subscriptions}
            // getRowProps={onRowClick}
            isSearchable={true}
            theadClass="table-dark"
          />
        </Card>
      </div>
      <DeleteConfirmation
        show={showDeleteConf}
        setShow={setShowDeleteConf}
        onClose={(success) => {
          if (success) {
            handleDeletePlan(deleteItemId);
          }
        }}
      />
    </>
  )
}

export default ViewPlans;
