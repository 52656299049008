import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FormInput } from '../../../components'
import Spinners from '../../../components/Spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

type propsType = {
  onAddItemSuccess: any
  addParticularHMS: any
}

interface ParticularHMSItem {
  method?: string
  endpoint?: string
  hmsId?: string
}
const schemaResolver = yupResolver(
  yup.object().shape({
    endpoint: yup.string().required('Please enter Endpoint'),
    method: yup.string().required('Please enter Method'),
  }),
)

const AddData = (props: propsType) => {
  const { addParticularHMS, onAddItemSuccess } = props
  const [loading, setLoading] = useState<boolean>(false)
  const { id }: any = useParams()
  const methods = useForm<ParticularHMSItem>({
    resolver: schemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods
  const onSubmit = async (formData: ParticularHMSItem) => {
    console.log(formData)
    formData = {
      ...formData,
      hmsId: id,
    }
    try {
      setLoading(true)

      let res: Response = (await addParticularHMS(formData)) as Response

      if (res.status === 200) {
        toast.success('Add Method successfully.')
        onAddItemSuccess()
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Row>
      <Col>
        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
          {/* <FormInput
            label="HMS"
            type="text"
            name="hms"
            register={register}
            key="hms"
            required
            errors={errors}
            control={control}
            containerClass={'mb-3'}
          /> */}
          <Form.Group className="">
            <Form.Label column htmlFor="hms" className="">
              Method
            </Form.Label>
            <Col lg={10}>
              <FormInput
                type="text"
                name="method"
                className="form-control chat-input"
                placeholder="Enter Method"
                register={register}
                key="method"
                errors={errors}
                control={control}
              />
            </Col>
            <Form.Label column htmlFor="authEndpoint" className="">
              Endpoint
            </Form.Label>
            <Col lg={10}>
              <FormInput
                type="text"
                name="endpoint"
                className="form-control chat-input"
                placeholder="Enter Endpoint"
                register={register}
                key="endpoint"
                errors={errors}
                control={control}
              />
            </Col>
          </Form.Group>
          <div className="text-md-end mb-0">
            {loading ? (
              <Spinners classname="px-3" />
            ) : (
              <Button variant="primary" className="mt-2" type="submit">
                Add Method
              </Button>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  )
}

export default AddData
