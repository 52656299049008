import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getParticularHMS(params?: { hmsId?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/apis`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addParticularHMS(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/apis`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateParticularHMS(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/apis/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteParticularHMS(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/apis/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export {
  getParticularHMS,
  addParticularHMS,
  deleteParticularHMS,
  updateParticularHMS,
}
