import { APICore } from './apiCore'
import config from '../../config'
import { Response } from '../../constants/types'

const api = new APICore()

let baseUrl = config.API_URL

async function getAllHMS(params?: { floor?: number }) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}`

      let result: Promise<Response> = api.get(url, params)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function addHMS(body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}`
      console.log(body)

      let result: Promise<Response> = api.create(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function updateHMS(id: number, body: any) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/id/${id}`

      let result: Promise<Response> = api.updatePatch(url, body)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

async function deleteHMS(id: number) {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${baseUrl}${config.HMS}/id/${id}`

      let result: Promise<Response> = api.delete(url)
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

export { getAllHMS, addHMS, deleteHMS, updateHMS }
