import { Button, Col, Row } from 'react-bootstrap'
import * as yup from 'yup'

// images
// import SmallOffice from "../../../assets/images/smallOfficeDesc.png";
import { yupResolver } from '@hookform/resolvers/yup'
import { FormInput } from '../../components'

// types
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Spinners from '../../components/Spinner'
import { currencyList, periodList } from '../../constants/select-options'
import { Response } from '../../constants/types'
import { Item } from './../../types/Item'
import { addPlan } from '../../helpers/api/superAdmin'

type propsType = {
    onAddItemSuccess: any
    addPlan: any
    selectedCategoryId?: number
}

export const itemSchemaResolver = yupResolver(
    yup.object().shape({
        // name: yup.string().required('Please enter hotel name'),
        price: yup.number().required('Please enter charge.').positive('Price can not be negative').integer("Price must be an integer."),
        currency: yup.string().required('Please enter currency.'),
        period: yup.string().required('1Please select period.'),
        GST: yup
            .number()
            .required('Please enter GST.')
            .typeError('Price must be a number')
            .positive('Price can not be negative'),
    }),
)

const AddPlan = (props: propsType) => {
    const { addPlan, onAddItemSuccess } = props
    const [loading, setLoading] = useState<boolean>(false)



    const onSubmit = async (formData: Item) => {
        console.log("add plan submit");
        try {
            setLoading(true)
            const data = {
                ...formData
            }

            let res: Response = await addPlan(data) as Response
            console.log('res_123', res);


            if (res.status === 200) {
                toast.success('Add plan successfully.');
                onAddItemSuccess();
            } else {
                console.log(res)
            }
        } catch (error) {
            console.log('error-----', error)
        } finally {
            setLoading(false)
        }
    }
    const methods = useForm<Item>({
        resolver: itemSchemaResolver,
    })
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        watch,
    } = methods


    return (
        <Row>
            <Col>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <FormInput
                        label="Name"
                        type="text"
                        name="name"
                        register={register}
                           key="name"
                        errors={errors}
                        control={control}
                        containerClass={'mb-3'}
                    /> */}
                    <FormInput
                        label={'Price'}
                        type="number"
                        min={0}
                        step={1}
                        name="price"
                        key="price"
                        containerClass={'mb-3'}
                        {...{ register, errors, control }}
                    />

                    <FormInput
                        label="Currency"
                        type="select"
                        name="currency"
                        key="currency"
                        containerClass={'mb-3'}
                        defaultValue='INR'
                        {...{ register, errors, control }}
                    >
                        {currencyList?.map((currency) => (
                            <option value={currency?.value} key={currency?.value}>
                                {currency?.label}
                            </option>
                        ))}
                    </FormInput>

                    {/* <FormInput
                        label={'Duration (In months)'}
                        type="number"
                        min={0}
                        step={0.01}
                        name="durationMonth"
                        key="durationMonth"
                        containerClass={'mb-3'}
                        {...{ register, errors, control }}
                    /> */}

                    <FormInput
                        label="Period"
                        type="select"
                        name="period"
                        key="period"
                        containerClass={'mb-3'}
                        {...{ register, errors, control }}
                    >
                        {periodList?.map((period) => (
                            <option value={period?.value} key={period?.value}>
                                {period?.label}
                            </option>
                        ))}
                    </FormInput>

                    <FormInput
                        label={'GST'}
                        type="number"
                        min={0}
                        step={0.01}
                        name="GST"
                        key="GST"
                        containerClass={'mb-3'}
                        {...{ register, errors, control }}
                    />


                    <div className="text-md-end mb-0">
                        {loading ? (
                            <Spinners classname="px-3" />
                        ) : (
                            <Button variant="primary" className="me-1" type="submit">
                                Add Plan
                            </Button>
                        )}
                    </div>
                </form>
            </Col>
            {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
        </Row>
    )
}

export default AddPlan
