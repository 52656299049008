import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import moment from 'moment'

import { Response } from '../../constants/types'
import { GetHouseKeepingItemsByHotel } from '../../helpers/api/superAdmin'
import Loader from '../Loader'

import Table from '../Table'

import NoDataFound from '../NoDataFound'
import { getCategoryList } from '../../helpers/api/categories'
import { Category } from '../../types/Category'

const columns = [
  {
    Header: 'Item ID',
    accessor: 'id',
  },
  {
    Header: '',
    accessor: 'categoryId',
    Cell: <></>,
  },
  {
    Header: 'Item name',
    accessor: 'name',
  },
  {
    Header: 'Item price',
    accessor: 'actualPrice',
  },
  {
    Header: 'Hotelcom Charge',
    accessor: 'price',
    Cell: ({ row }: any) => {
      const { price, actualPrice } = row?.original
      return price - actualPrice
    }
  },
  // {
  //   Header: 'Time',
  //   accessor: 'time',
  //   Cell: ({ row }: any) => {
  //     const time = row.original?.time
  //     // const time = new Date()
  //     return <div>{moment(time).local().format('HH:mm:ss a')}</div>
  //   },
  // },
  // {
  //   Header: 'Stock',
  //   accessor: 'stock',
  // },
]

const HouseKeeping = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [options, setOptions] = useState<{ value: any; label: string }[]>([])
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [houItemList, setHouItemList] = useState([])

  const getHouItemList = useCallback(
    async (categoryId?: number) => {
      setPageLoad(true)
      const params = categoryId ? { categoryId } : {}
      let res: Response = (await GetHouseKeepingItemsByHotel(
        hotelId,
        params,
      )) as Response
      if (res?.data?.data) {
        setHouItemList(res?.data?.data)
      }
      setPageLoad(false)
    },
    [hotelId],
  )

  const getAllCategory = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await getCategoryList({
      section: 'housekeeping',
    })) as Response
    if (res?.data?.data) {
      setCategoryList(res?.data?.data)
    }
    setPageLoad(false)
  }, [])

  useEffect(() => {
    getAllCategory()
  }, [getAllCategory])

  useEffect(() => {
    if (categoryList)
      setOptions(categoryList.map((cat) => ({ value: cat, label: cat.name })))
  }, [categoryList])

  useEffect(() => {
    getHouItemList(selectedCategory?.value?.id)
  }, [getHouItemList, selectedCategory])

  return (
    <>
      {pageLoad && <Loader />}
      {/* <div>
        <Select
          placeholder="Select Category"
          className="react-select react-select-container w-25"
          classNamePrefix="react-select"
          value={selectedCategory}
          onChange={(option) => setSelectedCategory(option)}
          options={options}
        ></Select>
      </div> */}
      <div>
        <Card className="mt-2">
          <Card.Body>
            {houItemList?.length > 0 ? (
              <Table
                columns={columns}
                data={houItemList}
                isSearchable={true}
                isExportable={true}
                theadClass="table-dark"
              />
            ) : (
              <NoDataFound />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default HouseKeeping
