import { useCallback, useEffect, useState } from 'react'

import Routes from './routes/Routes'
import './index.css'

//import { configureFakeBackend } from './helpers';

// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss'
import { Response } from './constants/types'
import { getProfile } from './helpers/api/admin'
import { ProfileContext } from './Context'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/ReactToastify.min.css'
import 'react-datepicker/dist/react-datepicker.css'

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
//configureFakeBackend();

type User = {
  floors: number
  name: string
  image: string
  id: number
  email: string
  contactNo: string
  address: string
  GST: string
  IFSC: string
  PAN: string
  bancAccHolderName: string
  bancAccNo: string
  bancBranchName: string
}

const App = () => {
  const [userName, setUserName] = useState<string>('')
  const [user, setUser] = useState<User>({
    floors: 0,
    name: 'Admin',
    image: '',
    id: 0,
    email: '',
    contactNo: '',
    address: '',
    GST: '',
    IFSC: '',
    PAN: '',
    bancAccHolderName: '',
    bancAccNo: '',
    bancBranchName: '',
  })
  const [userImg, setUserImg] = useState<string>('')
  const [isAdmin, setIsAdmin] = useState(false)

  const setNewUsername = (newUsername: string, imageUrl: string) => {
    console.log({ newUsername })
    setUserImg(imageUrl)
    setUserName(newUsername)
  }

  const getProfileData = useCallback(async () => {
    if (localStorage.getItem('AUTH_TOKEN')) {
      let res: Response = (await getProfile()) as Response
      if (res.status === 200) {
        setUserName(res?.data?.data?.name)
        setUser(res?.data?.data)
      }
    }
  }, [])

  useEffect(() => {
    getProfileData()
    setIsAdmin(JSON.parse(localStorage.getItem('isAdmin') || 'false'))
  }, [getProfileData])

  return (
    <>
      <div id="recaptcha-container"></div>
      <ProfileContext.Provider
        value={{
          userName,
          userImg,
          setNewUsername,
          getProfileData,
          isAdmin,
          setIsAdmin,
          user,
        }}
      >
        <Routes />
      </ProfileContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default App
