const config = {
  API_URL: process.env.REACT_APP_BASE_URL,
  CATEGORIES: '/categories',
  CUSTOM_FIELDS: '/custom/fields',
  ADMINS: '/admins',
  HMS: '/hms',
  HOTELS: '/hotels',
  RESTAURANTS_ITEMS: '/restaurants/items',
  HOUSEKEEPING_ITEMS: '/housekeeping/items',
  DEVICE_GUIDE: '/device/guides',
  CONTACTS: '/contacts',
  HOTELIERS_FEEDBACKS: '/hoteliers/feedbacks',
  GUEST_FEEDBACK_HOTELS: '/guests/feedbacks/hotels',
  ROOMS: '/rooms',
  GET_LOCATION: '/location',
  GET_RESOURCES: '/resourcetype',
  GET_INVOICE: '/invoice',
  GET_BOOKING: '/booking',
  GET_ANNOUCEMENTS: '/announcement',
  UPDATE_ANNOUCEMENTS: '/announcement',
  ADD_ANNOUCEMENTS: '/announcement',
  INFO: '/info',
  RESOURCES: '/resource',
  RESOURCES_TYPES: '/resourcetype',
  GET_USER: '/user',
  DASHBOARD: '/dashboard',
  LOCKS: 'https://api.kisi.io/locks',
  UNIVERSAL_URL: 'https://www.universal-tutorial.com/api',
  GET_UNIVERSAL_TOKEN: '/getaccesstoken',
  GET_COUNTRY: '/countries',
  GET_STATE: '/states/',
  GET_CITY: '/cities/',
  GET_TEAM: '/team',
  PLANS: '/plan',
  NOTIFICATION: '/user/sendpushnotification',
  REPORTS: '/appreport',
  VERIFY_USER: '/user/verify',
  USER_SIGNUP: '/user/signup',
  ADD_PLAN: '/plans',
}

export default config
