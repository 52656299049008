import { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { Button, Card, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Response } from '../../constants/types'
import { GetOrdersByHotel } from '../../helpers/api/superAdmin'
import Loader from '../Loader'
import NoDataFound from '../NoDataFound'
import Table from '../Table'
import { CSVLink } from 'react-csv'

import placeholderImage from '../../assets/images/placeholderImage.png'
import moment from 'moment'

const statusList = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'Accepted',
    label: 'Accepted',
  },
  {
    value: 'Rejected',
    label: 'Rejected',
  },
  {
    value: 'Confirmed',
    label: 'Confirmed',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
]

const deptList = [
  {
    value: 'Housekeeping',
    label: 'Housekeeping',
  },
  {
    value: 'Restaurant',
    label: 'Restaurant',
  },
]

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Item Name',
    accessor: 'restaurantItem',
    Cell: ({ value, row }: any) => {
      let name, image
      if (value) {
        name = value.name
        image = value.image
      } else {
        const { houseKeepingItem } = row.original
        name = houseKeepingItem.name
        image = houseKeepingItem.image
      }
      // debugger
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div className="w-50 text-start">
            <img
              src={image || placeholderImage}
              className="rounded-circle bg-secondary"
              alt=""
              style={{
                height: '40px',
                width: '40px',
              }}
            />
            <span className="ms-2" style={{ textAlign: 'left' }}>
              {name}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const { status, completedAt, confirmedAt, createdAt } = row.original
      return <span>{moment(createdAt).local().format('MMM DD, yyyy')}</span>
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: ({ row }: any) => {
      const { price } = row.original
      return <span>{+price}</span>
    },
  },
  {
    Header: 'Room No.',
    accessor: 'room.roomNo',
  },
  // {
  //   Header: 'Trasnsaction ID',
  //   accessor: ''
  // }
]

const HotelOrder = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [orderList, setOrderList] = useState([])
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')

  const [status, setStatus] = useState<any>()
  const [dept, setDept] = useState<any>()

  const getOrderList = useCallback(
    async (params?: any) => {
      setPageLoad(true)
      let res: Response = (await GetOrdersByHotel(hotelId, params)) as Response
      if (res?.data?.data) {
        setOrderList(res?.data?.data)
      }
      setPageLoad(false)
    },
    [hotelId],
  )

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     getOrderList({ startDate, endDate })
  //   }
  // }, [endDate, getOrderList, startDate])

  useEffect(() => {
    getOrderList()
  }, [getOrderList])

  useEffect(() => {
    let params = { status: '', department: '' }
    if (status?.value) params.status = status.value
    if (dept?.value) params.department = dept.value
    getOrderList(params)
  }, [dept, getOrderList, status])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="mt-2">
        <Card.Body>
          <Card className="">
            <Card.Body>
              <Card.Title>
                <div className="d-flex justify-content-between">
                  <span>Date Range</span>
                  <CSVLink data={orderList}>
                    <Button variant="dark">Export CSV</Button>
                  </CSVLink>
                </div>
              </Card.Title>
              <div className="d-flex gap-4">
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="startDate">Start Date</Form.Label>
                    <Form.Control
                      id="startDate"
                      type="date"
                      name="startDate"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="w-100">
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="endDate">End Date</Form.Label>
                    <Form.Control
                      id="endDate"
                      type="date"
                      name="endDate"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value)
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  onClick={() => {
                    getOrderList({ startDate, endDate })
                  }}
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    setStartDate('')
                    setEndDate('')
                    getOrderList()
                  }}
                >
                  Clear
                </Button>
              </div>
            </Card.Body>
          </Card>

          <div className="d-flex gap-2">
            <Select
              placeholder="Filter by Status"
              className="react-select react-select-container w-25"
              classNamePrefix="react-select"
              value={status}
              onChange={(option) => setStatus(option)}
              options={statusList}
              defaultValue={statusList[0]}
            />
            <Select
              placeholder="Filter by Department"
              className="react-select react-select-container w-25"
              classNamePrefix="react-select"
              value={dept}
              onChange={(option) => setDept(option)}
              options={deptList}
            />
          </div>
          {orderList?.length > 0 ? (
            <Table
              columns={columns}
              data={orderList}
              // isSearchable={true}
              theadClass="table-dark"
            />
          ) : (
            <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "150px" }}>
              <NoDataFound />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default HotelOrder
