import { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Response } from '../../constants/types'
import { GetAnalyticsOfHotel } from '../../helpers/api/superAdmin'
import Loader from '../Loader'

type DerStateType = {
  totalGuest: number
  currentGuest: number
  roomsReserved: number
  roomsUnreserved: number
  hotelRevenue: number | string
  hotelcomRevenue: number | string
  revenuePercent: number
  roomsPercent: number
}

const HotelAnalytics = () => {
  const { hotelId } = useParams<{ hotelId: string }>()

  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [anl, setAnl] = useState({
    guests: [],
    completedOrders: { order: 0 },
    hoteliers: { count: 0 },
    room: [],
    revenueForAdminDashboard: {
      hotelRevenue: {
        amount: '0',
      },
      hotelcomRevenue: {
        amount: '0',
      },
    },
  })
  const [derState, setDerState] = useState<DerStateType>({
    totalGuest: 0,
    currentGuest: 0,
    roomsReserved: 0,
    roomsUnreserved: 0,
    hotelRevenue: 0,
    hotelcomRevenue: 0,
    revenuePercent: 0,
    roomsPercent: 0,
  })

  const getHotelAnl = useCallback(
    async (params?: any) => {
      setPageLoad(true)
      let res: Response = (await GetAnalyticsOfHotel(
        hotelId,
        params,
      )) as Response
      if (res?.data?.data) {
        setAnl(res?.data?.data)
      }
      setPageLoad(false)
    },
    [hotelId],
  )

  useEffect(() => {
    let totalGuest = 0
    let currentGuest = 0
    let roomsReserved = 0
    let roomsUnreserved = 0
    anl?.guests?.forEach((itm: any) => {
      const count: number = +itm?.guests || 0
      if (itm?.isActive) {
        currentGuest = count
      }
      totalGuest += count
    })

    anl?.room?.forEach((itm: any) => {
      const count: number = +itm?.count || 0
      if (itm?.isAvailable) {
        return (roomsUnreserved = count)
      }
      return (roomsReserved = count)
    })
    const hotelRevenue =
      parseInt(anl?.revenueForAdminDashboard?.hotelRevenue?.amount) || 0
    const hotelcomRevenue =
      parseInt(anl?.revenueForAdminDashboard?.hotelcomRevenue?.amount) || 0
    const totalRevenue = hotelRevenue + hotelcomRevenue
    const revenuePercent =
      totalRevenue === 0
        ? 0
        : Math.round((hotelcomRevenue / totalRevenue) * 100)
    const totalRooms = roomsReserved + roomsUnreserved
    const roomsPercent =
      totalRooms === 0 ? 0 : Math.round((roomsReserved / totalRooms) * 100)
    setDerState((prev) => ({
      ...prev,
      currentGuest,
      totalGuest,
      hotelRevenue,
      hotelcomRevenue,
      revenuePercent,
      roomsReserved,
      roomsUnreserved,
      roomsPercent,
    }))
  }, [anl])

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     getHotelAnl({ startDate, endDate })
  //   }
  // }, [endDate, getHotelAnl, startDate])

  useEffect(() => {
    getHotelAnl()
  }, [getHotelAnl])

  return (
    <>
      {pageLoad && <Loader />}
      <Card className="p-3">
        <Card className="">
          <Card.Body>
            <Card.Title>Date Range</Card.Title>
            <div className="d-flex gap-4">
              <div className="w-100">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="startDate">Start Date</Form.Label>
                  <Form.Control
                    id="startDate"
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                  />
                </Form.Group>
              </div>
              <div className="w-100">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="endDate">End Date</Form.Label>
                  <Form.Control
                    id="endDate"
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                onClick={() => {
                  getHotelAnl({ startDate, endDate })
                }}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  setStartDate('')
                  setEndDate('')
                  getHotelAnl()
                }}
              >
                Clear
              </Button>
            </div>
          </Card.Body>
        </Card>
        <Row className="mt-3 gap-2">
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Total Guest</Card.Title>
                <p className="fw-bold fs-4">{derState?.totalGuest}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Current Guest</Card.Title>
                <p className="fw-bold fs-4">{derState?.currentGuest}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Completed Orders</Card.Title>
                <p className="fw-bold fs-4">{anl?.completedOrders?.order}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3 gap-2">
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Hoteliers</Card.Title>
                <p className="fw-bold fs-4">{anl?.hoteliers?.count}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>Rooms</Card.Title>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fw-bold fs-4 mb-1">{`Reserved - ${derState?.roomsReserved}`}</p>
                    <p className="fs-5">{`Unreserved - ${derState?.roomsUnreserved}`}</p>
                  </div>
                  <div
                    className="d-flex rounded-circle border border-dark justify-content-center align-items-center px-2"
                    style={{
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    {`${derState.roomsPercent}%`}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {/* <Card className="h-100">
              <Card.Body>
                <Card.Title>Revenue</Card.Title>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className=" fw-bold fs-4 mb-1">
                      {`Hotelcom - ${derState.hotelcomRevenue}`}
                    </p>
                    <p className="fs-5">
                      {`Hotels - ${derState.hotelRevenue}`}
                    </p>
                  </div>
                  <div
                    className="d-flex rounded-circle border border-dark justify-content-center align-items-center px-2"
                    style={{
                      width: '50px',
                      height: '50px',
                    }}
                  >
                    {`${derState.revenuePercent}%`}
                  </div>
                </div>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default HotelAnalytics
