import {
  Dissatisfied,
  Neutral,
  Satisfied,
  VeryDissatisfied,
  VerySatisfied,
} from '../../assets/svgs/FeedbackEmojis'

interface EmojiMap {
  [key: number]: any
}

const numberToEmoji: EmojiMap = {
  1: VeryDissatisfied,
  2: Dissatisfied,
  3: Neutral,
  4: Satisfied,
  5: VerySatisfied,
}

type Row = {
  row: {
    values: {
      rating: number
    }
  }
}

const Expression = ({row}: Row) => {
  const feedback = row?.values?.rating
  return <img src={numberToEmoji[feedback]} alt="" />
}

export default Expression
