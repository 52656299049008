import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Loader from '../../components/Loader'
import NoDataFound from '../../components/NoDataFound'
import Table from '../../components/Table'
import { Response } from '../../constants/types'
import { GetAllFeedBacks } from '../../helpers/api/superAdmin'
import ReadMore from '../../utils/components/ReadMore'
import Expression from '../dashboard/Feedback/Expression'

const columns = [
  // {
  //   Header: 'Hotel Id',
  //   accessor: 'hotelId',
  // },
  {
    Header: 'Hotel Name',
    accessor: 'hotel.name',
  },
  {
    Header: 'Feedback',
    accessor: 'feedback',
    Cell: ({ value }: any) => {
      return <ReadMore>{value}</ReadMore>
    },
  },
  {
    Header: 'Expression',
    accessor: 'rating',
    Cell: Expression,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const createdAt = row.original?.createdAt
      return <div>{moment(createdAt).local().format('MMM DD, yyyy')}</div>
    },
  },
  {
    Header: 'Time',
    accessor: '',
    Cell: ({ row }: any) => {
      const createdAt = row.original?.createdAt
      return <div>{moment(createdAt).local().format('hh:mm A')}</div>
    },
  },
]

const HotelFeedback = () => {
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [feedbackList, setFeedbackList] = useState([])

  const getFeedbackList = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await GetAllFeedBacks()) as Response
    setFeedbackList(res?.data?.data)
    setPageLoad(false)
  }, [])

  useEffect(() => {
    getFeedbackList()
  }, [getFeedbackList])

  return (
    <>
      {pageLoad && <Loader />}
      <div className="mt-2">
        <div className="d-flex gap-3 align-items-center">
          <span className="fw-bold fs-3">Feedbacks</span>
          <div className="d-flex gap-1">
            <span>Home</span>
            <span>|</span>
            <span>Feedbacks</span>
          </div>
        </div>
      </div>

      <Card className="mt-2">
        <Card.Body>
          {feedbackList?.length > 0 ? (
            <Table
              columns={columns}
              data={feedbackList}
              isSearchable={true}
              isExportable={true}
              theadClass="table-dark"
            />
          ) : (
            <NoDataFound />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default HotelFeedback
