import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Spinners from '../../components/Spinner'
import { Item } from '../../types/Item'
import { FormInput } from '../../components'
import { toast } from 'react-toastify'

type propsType = {
  planList: any[]
  editItem: Item
  onEditItemSuccess: any
  updatePlan: any
}

const itemSchemaResolver = yupResolver(
  yup.object().shape({
    plan: yup.number().required('Please select Plan'),
  }),
)

const UpdatePlan = (props: propsType) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { planList, editItem, updatePlan, onEditItemSuccess } = props

  const { plan, id } = editItem
  //   let planId = ''
  //   console.log(id)

  const onSubmit = async (formData: Item) => {
    // console.log('submite')
    try {
      setLoading(true)
      const data = {
        plan: formData?.plan,
      }

      //   console.log(data)

      let res: Response = (await updatePlan(id, data)) as Response
      if (res.status === 200) {
        toast.success('Update Plan successfully')
        onEditItemSuccess({
          plan: formData?.plan,
          data: { id, ...formData },
        })
      } else {
        console.log(res)
      }
    } catch (error) {
      console.log('error-----', error)
    } finally {
      setLoading(false)
    }
  }

  const methods = useForm<Item>({
    resolver: itemSchemaResolver,
    defaultValues: { plan: plan },
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = methods

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <Form.Group className="mb-3">
            <Form.Label>Select Plan</Form.Label>
            <Form.Select
              {...register('categoryId')}
              name="categoryId"
              id="categoryId"
              isInvalid={errors && errors['categoryId'] ? true : false}
            >
              {planList?.map((cat) => (
                <option value={cat?.id} key={cat?.id}>
                  {cat?.name}
                </option>
              ))}
            </Form.Select>

            {errors && errors['categoryId'] ? (
              <Form.Control.Feedback type="invalid">
                {errors['categoryId']['message']}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <FormInput
            label={'Name'}
            type="text"
            name="name"
            placeholder="Enter Name"
            key="name"
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          /> */}
          <FormInput
            label={'Select Plan'}
            type="select"
            name="plan"
            placeholder=""
            containerClass={'mb-3'}
            {...{ register, errors, control }}
          >
            {planList?.map((cat) => (
              <option value={cat?.id} key={cat?.id}>
                {cat?.title}
              </option>
            ))}
          </FormInput>

          <div className="text-md-end mb-0">
            {loading ? (
              <Spinners classname="px-3" />
            ) : (
              <Button variant="primary" className="me-1" type="submit">
                Update Plan
              </Button>
            )}
          </div>
        </form>
      </Col>
      {/* <Col xs={4}>
                <img src={SmallOffice} alt="office" />
            </Col> */}
    </Row>
  )
}

export default UpdatePlan
