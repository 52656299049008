import { useState } from 'react'
import { Button, Col, Form, Navbar, Row, Card } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import logo from '../../../assets/images/logo-sm.png'
import { FormInput } from '../../../components'

import { createAdmin } from '../../../helpers/api/admin'
import { Link, useHistory } from 'react-router-dom'

import { Response } from '../../../constants/types'

export interface RegisterData {
  name: string
  email: string
  password: string
  address: string
  contactNo: number
  bancAccNo: string
  bancAccHolderName: string
  bancBranchName: string
  IFSC: string
  GST: string
  PAN: string
}

const Register = () => {
  const history = useHistory()

  const [formType, setFormType] = useState('Sign up')

  const registerSchemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required('Please enter hotel name'),
      email: yup.string().required('Please enter Email address').email(),
      password: yup
        .string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.'),
      address: yup.string().required('Please enter address'),
      contactNo: yup
        .number()
        .typeError('Please enter valid Contact number')
        .required('please enter Contact number')
        .min(1000000000, 'Please enter valid Contact number')
        .max(9999999999, 'Please enter valid Contact number'),
      bancAccNo: yup.string().required('please enter bank account number'),
      bancAccHolderName: yup
        .string()
        .required('Please enter bank account holder name'),
      bancBranchName: yup.string().required('Please enter bank branch name'),
      IFSC: yup.string().required('Please enter IFSC code'),
      GST: yup.string().required('Please enter GST'),
      PAN: yup.string().required('Please enter pan number'),
    }),
  )

  const methods = useForm<RegisterData>({
    defaultValues: {},
    resolver: registerSchemaResolver,
  })
  const {
    handleSubmit,
    register,
    control,
    trigger,
    formState: { errors },
  } = methods

  const onSubmit = async (formData: RegisterData) => {
    let res: Response = (await createAdmin(formData)) as Response
    console.log(res)
    if (res.status === 200) {
      localStorage.setItem('AUTH_TOKEN', res.data.token)
      localStorage.setItem('isAdmin', (res.data.role === 'Admin').toString())
      history.push('/dashboard')
    } else {
      console.log(res)
    }
  }

  console.log('errors ----->', errors)

  const gotoPaymentPage = async () => {
    const valid = await trigger(['name', 'address', 'contactNo'])
    if (valid) setFormType('Payment details')
  }

  const gotoPropertyPage = async () => {
    const valid = await trigger(['email', 'password'])
    if (valid) setFormType('Property details')
  }

  return (
    <div>
      <Navbar bg="light" className="d-flex align-items-center">
        <img src={logo} width={30} height={30} alt="" />
        <div className="m-auto">
          <p className="m-auto fs-3">Get connection</p>
        </div>
      </Navbar>
      <div className="p-4">
        <div className="d-flex justify-content-center align-items-center p-4 pt-3">
          <p className="fs-4">{formType}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formType === 'Sign up' && (
            <Col md={6} className="m-auto">
              <Card>
                <Card.Body>
                  <Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Email Address
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="email"
                          name="email"
                          register={register}
                          key="email"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Password
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="password"
                          name="password"
                          register={register}
                          key="password"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <div className="mt-2 d-flex justify-content-center">
                      <p>
                        Already have an account?{' '}
                        <Link to="/auth/Login">Login</Link>
                      </p>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}
          {formType === 'Property details' && (
            <Col md={6} className="m-auto">
              <Card>
                <Card.Body>
                  <h4 className="">Hotel details</h4>
                  <Row>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Hotel name
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="name"
                          register={register}
                          key="name"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Hotel address
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="address"
                          register={register}
                          key="address"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="contactNo">
                        Hotel contact number
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="number"
                          name="contactNo"
                          register={register}
                          key="contactNo"
                          errors={errors}
                          control={control}
                          defaultValue={undefined}
                        />
                      </Col>
                    </Form.Group>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}
          {formType === 'Payment details' && (
            <Card>
              <Card.Body>
                <h4 className="">Bank details</h4>

                <Row>
                  <Col md={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Account number
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="bancAccNo"
                          register={register}
                          key="bancAccNo"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Branch name
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="bancBranchName"
                          register={register}
                          key="bancBranchName"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        Account holder name
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="bancAccHolderName"
                          register={register}
                          key="bancAccHolderName"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column lg={3} htmlFor="simpleinput">
                        IFSC
                      </Form.Label>
                      <Col lg={9}>
                        <FormInput
                          type="text"
                          name="IFSC"
                          register={register}
                          key="IFSC"
                          errors={errors}
                          control={control}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Card>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={3} htmlFor="simpleinput">
                            GST number
                          </Form.Label>
                          <Col lg={9}>
                            <FormInput
                              type="text"
                              name="GST"
                              register={register}
                              key="GST"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column lg={3} htmlFor="simpleinput">
                            PAN number
                          </Form.Label>
                          <Col lg={9}>
                            <FormInput
                              type="text"
                              name="PAN"
                              register={register}
                              key="PAN"
                              errors={errors}
                              control={control}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          )}
          <div className="d-flex justify-content-between">
            <a href="tel:8128220770">Call for assistance</a>
            <div className="d-flex gap-2">
              {formType === 'Sign up' && (
                <Button onClick={gotoPropertyPage}>Sign up</Button>
              )}
              {formType === 'Property details' && (
                <>
                  <Button onClick={() => setFormType('Sign up')}>Back</Button>
                  <Button onClick={gotoPaymentPage}>Next</Button>
                </>
              )}
              {formType === 'Payment details' && (
                <>
                  <Button onClick={() => setFormType('Property details')}>
                    Back
                  </Button>
                  <Button type="submit">Get connection</Button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register
