import React, { CSSProperties } from "react";
import classNames from "classnames";
import { Button, Spinner } from "react-bootstrap";

/**
 * Spinner
 */

type SpinnerProp = {
  classname?: string;
  styles?: CSSProperties;
};

const Spinners = (props: SpinnerProp) => {
  return (
    <Button
      variant="primary"
      disabled
      className={props.classname}
      style={props.styles}
    >
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <span className="visually-hidden">Loading...</span>
    </Button>
  );
};

export default Spinners;
