import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import classes from './invoice.module.css'

// components

// dummy data
// import { invoiceData } from "./data";

// images
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { toast } from 'react-toastify'
import logo from '../../assets/images/logo.png'
import { getSingleSubscriptionById } from '../../helpers/api/superAdmin'
import { capitalize } from './../../utils/funcs'

// invoice component
const NewInvoice = () => {
  const location = useLocation<any>()

  // let { invoiceDetails, invoice } = location?.state;
  const params: any = useParams();


  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [sub, setSub] = useState<any>({})
  const [hotelcomDetails, setHotelcomDetails] = useState<any>({})

  console.log('101', location?.state)

  const printDocument = () => {
    const invoiceMain = document.getElementById('invoiceMain')

    console.log('invoiceMain"', invoiceMain)

    if (invoiceMain) {
      html2canvas(invoiceMain).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const imgProps = pdf.getImageProperties(canvas)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        console.log('pdfWidth')

        // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        // const pdfWidth = 794;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        // pdf.output('dataurlnewwindow');        
        pdf.save(`invoice_${sub?.payId}.pdf`)
      })
    }
  }

  const getSubscriptionByIdInvoice = useCallback(
    async () => {
      try {
        setPageLoad(true)
        let res: any = (await getSingleSubscriptionById(params.subId, {})) as any
        console.log('res_101', res);

        setSub(res?.data?.subscription)
        setHotelcomDetails(res?.data?.hotelcomDetails)
      } catch (error: any) {
        toast.error(capitalize(error.errorMessage))
      } finally {
        setPageLoad(false)
      }
    },
    [params.subId],
  )

  useEffect(() => {
    getSubscriptionByIdInvoice()
  }, []);


  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1rem',
          marginRight: '0.5rem',
          gap: '1rem',
          position: 'relative',
        }}
      >
        <button
          className="waves-effect waves-light btn btn-primary"
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          type="button"
          onClick={printDocument}
        >
          <i className="bi bi-printer-fill"></i> Print
        </button>

        <div id="invoiceMain" className={classes.invoiceMain}>
          <div className={classes.clearance}>
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="grid invoice">
                    <div className="grid-body">
                      <div className="invoice-title">
                        <br />
                        <div className="row ms-2 mt-4">
                          <div
                            className="col-xs-12"
                            style={{ position: 'relative' }}
                          >
                            <img
                              src={logo}
                              style={{
                                position: 'absolute',
                                right: 0,
                                // marginRight: '5px',
                              }}
                              height={48}
                            />
                            <h2>Hotelcom Private Limited</h2>
                            <div className="" style={{ lineHeight: 0.5 }}>
                              <p className="" style={{ fontWeight: 600 }}>
                                <u>Registered Address</u>
                              </p>
                              <p className="small">
                                18, Raja Nagar, Ragavanpettai,
                              </p>
                              <p className="small">
                                Token No. Villuppuram, TN, 605602, IN
                              </p>
                              <p className="small">
                                CIN: {hotelcomDetails?.CIN?.trim()}
                              </p>
                              <p className="small">GSTIN: {hotelcomDetails?.GSTIN?.trim()}</p>
                              <p className="small">PAN: {hotelcomDetails?.PAN?.trim()}</p>
                              <p className="small">
                                Email Id: {hotelcomDetails?.Email?.trim()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row mx-2">
                        <div className="col-xs-6 text-end">
                          <address>
                            <strong>Invoice No. : </strong>
                            {`HC${sub?.id}`}
                          </address>
                        </div>
                      </div>

                      <div className="row mx-2">
                        <div className="col-xs-6">
                          <address>
                            <strong>Billed To:</strong>
                            <br />
                            {sub?.hotel?.name}
                            <br />
                            {sub?.hotel?.address}
                            <br />
                            {sub?.hotel?.city + ', ' + sub?.hotel?.state}
                            <br />
                            {capitalize(sub?.hotel?.country)}
                            <br />
                            <abbr>GST: </abbr>
                            {sub?.hotel?.GST}
                          </address>
                        </div>
                      </div>

                      <div className="row mx-2">
                        <div className="col-md-6 text-right">
                          <address>
                            <strong>Paid At:</strong>
                            <br />
                            {moment(sub?.createdAt).local().format('DD/MM/yyyy')}
                          </address>
                        </div>
                        {/* <div className="col-md-6 text-right">
                          <address>
                            <strong>Expires At:</strong>
                            <br />
                            {moment(sub?.expiryDate).local().format('DD/MM/yyyy')}
                          </address>
                        </div> */}
                      </div>
                      <div className="row mx-2">
                        <div className="col-md-6 text-right">
                          <address>
                            <strong>Payment Id:</strong>
                            <br />
                            {sub?.payId}
                          </address>
                        </div>
                      </div>

                      <div className="row mx-2">
                        <div className="col-md-12">
                          <h3>ORDER SUMMARY</h3>
                          <table className="table table-striped">
                            <thead>
                              <tr className="line">
                                {/* <td className="text-start">
                                  <strong>Subscription Description</strong>
                                </td> */}
                                <td className="text-center">
                                  <strong>Number of Rooms</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Amount</strong>
                                </td>
                                <td className="text-center">
                                  <strong>GST</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Total</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <td className="text-start">{invoice?.plan}</td> */}
                                <td className="text-center">
                                  {+sub?.quantity}
                                </td>
                                <td className="text-center">
                                  {(+sub?.newPlan?.pricePrRoom * +sub?.quantity)?.toFixed(2)}
                                </td>
                                <td className="text-center">
                                  {
                                    ((+sub?.newPlan?.pricePrRoom - +sub?.newPlan?.priceWithoutTax) * +sub?.quantity)?.toFixed(2)
                                  }
                                  ({+sub?.newPlan?.GST}%)
                                </td>
                                <td className="text-center">
                                  {(+sub?.newPlan?.pricePrRoom * +sub?.quantity)?.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewInvoice
