import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../components'
import Loader from '../../components/Loader'
import Spinners from '../../components/Spinner'
import { Response } from '../../constants/types'
import { toast } from 'react-toastify'
import {
  getCustomField,
  updateCustomField,
} from '../../helpers/api/customFields'

type CustomFields = {
  id: number
  // SGST: number
  // CGST: number
  // shareOnPrice: number
  offlinePaymentLimit: number
}

export const CustomFieldsSchemaResolver = yupResolver(
  yup.object().shape({
    // SGST: yup
    //   .number()
    //   .required('Please enter SGST')
    //   .typeError('SGST must be a number')
    //   .positive('SGST can not be negative'),
    // CGST: yup
    //   .number()
    //   .required('Please enter CGST')
    //   .typeError('CGST must be a number')
    //   .positive('CGST can not be negative'),
    // shareOnPrice: yup
    //   .number()
    //   .required('Please enter share On Price')
    //   .typeError('share On Price must be a number')
    //   .positive('share On Price can not be negative'),
  }),
)

const CustomFeilds = () => {
  const [pageLoad, setPageLoad] = useState<boolean>(false)
  const [field, setField] = useState<CustomFields>()

  const getFeedbackList = useCallback(async () => {
    setPageLoad(true)
    let res: Response = (await getCustomField()) as Response
    setField(res?.data?.data)
    setPageLoad(false)
  }, [])

  const methods = useForm<any>({
    defaultValues: useMemo(() => {
      return field
    }, [field]),
    resolver: CustomFieldsSchemaResolver,
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  const onSubmit = async (formData: CustomFields) => {
    setPageLoad(true)
    let res: Response = (await updateCustomField(formData)) as Response
    if (res.status === 200) {
      toast.success('Custom Fields updated successfully')
    } else {
      console.log(res)
    }
    setPageLoad(false)
  }

  useEffect(() => {
    getFeedbackList()
  }, [getFeedbackList])

  console.log(field)

  return (
    <>
      {pageLoad && <Loader />}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2 py-2 justify-content-between">
          <h3>{`Custom Fields`}</h3>
        </div>
        <div>
          {field && (
            <Card className="p-3 w-50 m-auto">
              <Row>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row>
                    <Col>
                      {/* <FormInput
                        type="number"
                        name="SGST"
                        register={register}
                        key="SGST"
                        errors={errors}
                        control={control}
                        label={'SGST'}
                        containerClass={'mb-3'}
                        defaultValue={field.SGST}
                      />
                      <FormInput
                        type="number"
                        name="CGST"
                        register={register}
                        key="CGST"
                        errors={errors}
                        control={control}
                        label={'CGST'}
                        containerClass={'mb-3'}
                        defaultValue={field.CGST}
                      /> */}
                      {/* <FormInput
                        type="number"
                        name="shareOnPrice"
                        register={register}
                        key="shareOnPrice"
                        errors={errors}
                        control={control}
                        label={'Share On Price'}
                        containerClass={'mb-3'}
                        defaultValue={field.shareOnPrice}
                      /> */}
                      <FormInput
                        type="number"
                        name="offlinePaymentLimit"
                        register={register}
                        key="offlinePaymentLimit"
                        errors={errors}
                        control={control}
                        label="Offline Payment Limit"
                        containerClass={'mb-3'}
                        defaultValue={field.offlinePaymentLimit}
                      />
                    </Col>
                  </Row>

                  <div className="text-md-end mb-0">
                    {pageLoad ? (
                      <Spinners classname="px-3" />
                    ) : (
                      <Button variant="primary" className="me-1" type="submit">
                        Save
                      </Button>
                    )}
                  </div>
                </form>
              </Row>
            </Card>
          )}
        </div>
      </div>
    </>
  )
}

export default CustomFeilds
